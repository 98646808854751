<template>
  <v-card flat class="box-shadow-soft border-radius-1em stat-box">
    <div class="stat-box__title">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div class="stat-box__text">
      <slot name="text">
        <v-icon v-if="icon">
          {{ icon }}
        </v-icon>
        &nbsp;
        {{ text }}
        &nbsp;
      </slot>
      <slot name="tooltip"></slot>

      <template v-if="tooltipText">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="isThemeLight ? 'black' : null"
              v-bind="attrs"
              v-on="on"
            >
              {{ tooltipIcon }}
            </v-icon>
          </template>
          <span>
            {{ tooltipText }}
          </span>
        </v-tooltip>
      </template>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "StatBox",

  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: String,
      required: false,
      default: null
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    tooltipIcon: {
      type: String,
      required: false,
      default: "help_outline"
    }
  }
}
</script>

<style lang="stylus">
.stat-box
  padding 1.5em 0
  height 8em
  text-align center

  &__title
    font-size 1.875em
    font-weight bold
    line-height 1.5

  &__text
    display flex
    align-items center
    justify-content center
    font-weight 700
    opacity 0.72

    .v-icon
      color inherit !important
</style>
